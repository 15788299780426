import React, {useState, useEffect} from 'react'
import { Label, Icon } from 'semantic-ui-react'
import "../styles/statusTable.css"
import StatusSuccess from './status_row_components/statusSuccess'
import StatusWaiting from './status_row_components/statusWaiting'
import StatusRunning from './status_row_components/statusRunning'
import StatusError from './status_row_components/statusError'

const StatusTable = (props) => {
  
  const [isOpened, setIsOpened] = useState(true);
  const [row, setRow] = useState();
  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }
  useEffect(() => {
    
    if (isOpened == false){
      setRow(null)
      return
    }
    console.log("Child: ", props.siteStatus)
    switch (props.siteStatus){
      
      case "Waiting":
        setRow(<StatusWaiting fields={props.siteFields} description={""}/>)
        // rows.push(<StatusWaiting fields={props.siteFields} description={""}/>);
        break;
      case "Running":
        setRow(<StatusRunning fields={props.siteFields} description={""}/>)
        // rows.push(<StatusRunning fields={props.siteFields} description={"running"}/>);
        break;
      case "Success":
        setRow(<StatusSuccess fields={props.siteFields} description={"資料已讀取"}/>)
        // rows.push(<StatusSuccess fields={props.siteFields} description={"info acquired"}/>);
        break;
      case "Error":
        setRow(<StatusError fields={props.siteFields} description={"讀取資料時發生錯誤"}/>)
        // rows.push(<StatusError fields={props.siteFields} description={"Unavailable"}/>);
        break;    
      default:
        setRow(<StatusWaiting fields={props.siteFields} description={""}/>)
        // rows.push(<StatusSuccess fields={props.siteFields} description={"info acquired"}/>);
        break;
    }
  }, [isOpened, props.siteStatus])
  return (
    <div id="table-frame">
      <table className="table">
          <thead>
            <tr style={{width: "100%"}}>
              <th className="site-name-column">{props.siteName}</th>
              <th className="site-message-column"></th>
              <th className="site-status-column">現狀</th>
              <th className="site-action-column">
                <Icon className='dropdown' 
                name={isOpened ? "triangle up" : "dropdown"}
                link onClick={toggle}/>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <StatusWaiting fields={props.siteFields} description={"info acquired"}/> */}
            {row}
          </tbody>
      </table>
    </div>
  )
}

export default StatusTable