import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosServer } from '../apis/axiosApi'; // Adjust the path if needed
import '../styles/copilot.css';
import '../styles/output.css';
import gradient_background from '../styles/gradient_background.jpeg' // Import blue/purple background image
import { Header, Input,  FormField, Button, Form, Divider } from 'semantic-ui-react'

import CopilotInputFrame from '../components/copilotInputFrame';

const Copilot = () => {

  return (
    <div className='white-background'>
      <div className='main-page-container'>
        <CopilotInputFrame/>
      </div>

    </div>
  )
}

export default Copilot