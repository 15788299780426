import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosServer } from '../apis/axiosApi'; // Adjust the path if needed
import '../styles/copilotInputFrame.css';
import gradient_background from '../styles/gradient_background.jpeg' // Import blue/purple background image
import { Header, Input,  FormField, Button, Form, Divider } from 'semantic-ui-react'

const CopilotInputFrame = () => {
    const [user, setUser] = useState(null);
    const [address, setAddress] = useState('');
    const [caseName, setCaseName] = useState('');
    const [registryUsername, setRegistryUsername] = useState('');
    const [registryPassword, setRegistryPassword] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      } else {
        navigate('/login');
      }
    }, [navigate]);

    const getCaseID = async() => { 
      console.log('Sending request to get case id');
      const response = await axiosServer({
        method: 'POST',
        url: 'api/get-caseID/', // Make sure this matches your Django URL pattern
        data: {
          address,
          case_name: caseName,
          script_username: registryUsername,
          script_password: registryPassword,
        },
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        console.log("ok")
        const caseID = response.data["case_id"]
        console.log("Case ID: ", caseID)
        setResponseMessage(response.data.output || 'Script executed successfully.')
        navigate('/output', { state: {address: address, case_name: caseName, case_id: caseID}})
        submitScriptRequest(caseID)
      })
      .catch ((error) => {
        console.error('Error getting case ID:', error.response || error.message);
      });
    };

    const submitScriptRequest = async (caseID) => {
      console.log("Inside second request")
      try {
        console.log('Sending request to run script');
        const response = await axiosServer({
          method: 'POST',
          url: 'api/run-script/', // Make sure this matches your Django URL pattern
          data: {
            address,
            case_name: caseName,
            script_username: registryUsername,
            script_password: registryPassword,
            case_id: caseID, 
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log(response)
        console.log('Script output:', response.data);
        setResponseMessage(response.data.output || 'Script executed successfully.');
      } catch (error) {
        console.error('Error running script:', error.response || error.message);
        setResponseMessage('Error running script. Please check the console for details.');
      }
    }

    const handleAddressSubmit = async (e) => {
      getCaseID()
      // console.log("inside address submit case id ", caseID)
      e.preventDefault();
    };

    if (!user) {
      return null;
    }
  return (
    <div className='input-frame'>
        <Header as='h1' className='form-title'>資料輸入</Header>
        <form className='form' onSubmit={handleAddressSubmit}>
            <div className='inputs'>
            <FormField className="input-component">
                <div htmlFor="address" className="input-title" >地址:</div>
                <Input
                type="text"
                id="address"
                placeholder='格式: 台北市Ｏ區ＯＯ(道路)Ｏ巷Ｏ弄Ｏ號Ｏ樓'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
                className='input-box'
                />
                <div className='input-description'>*除了道路和地下樓層用中文數字，其他請用阿拉伯數字 (1~9) </div>
                <div className='input-description'>*目前只支援台北市和謄本不需身份證字號的門牌  </div>
            </FormField>
            <FormField className="input-component">
                <div htmlFor="address" className="input-title" >案名:</div>
                <Input
                type="text"
                id="caseName"
                placeholder='例: 石濤美院, 東騰好光景美河畔 '
                value={caseName}
                onChange={(e) => setCaseName(e.target.value)}
                required
                className='input-box'
                />
                <div className='input-description'>*顯示在後台開發物件案名</div>
            </FormField>
            <Divider horizontal>帳號資訊</Divider>
            <FormField id='username-component' className="input-component">
                <div htmlFor="address" className="input-title" >地政資訊網站帳號:</div>
                <Input
                type="text"
                id="registryUsername"
                placeholder='請輸入密碼 (不會儲存) '
                value={registryUsername}
                onChange={(e) => setRegistryUsername(e.target.value)}
                required
                className='input-box'
                />
            </FormField>
            <FormField className="input-component">
                <div htmlFor="address" className="input-title" >地政資訊網站密碼:</div>
                <Input
                type="password"
                id="registryPassword"
                placeholder='請輸入密碼 (不會儲存) '
                value={registryPassword}
                onChange={(e) => setRegistryPassword(e.target.value)}
                required
                className='input-box'
                />
            </FormField>
            </div>
            <Button type="submit" id="submit-button" >送出</Button>
        </form>
    </div>
  )
}

export default CopilotInputFrame