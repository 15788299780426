import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosServer } from '../apis/axiosApi';
import { Grid, Input, Checkbox, Button, Dropdown } from 'semantic-ui-react';
import '../styles/login.css';
import logo from '../styles/logo.jpg'; // Import the logo image
import background from '../styles/housing.jpg'; // Import the background image

const languageOptions = [
  { key: 'en', value: 'en', text: '中文' },
  // Add other languages here
];

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axiosServer({
        method: 'POST',
        url: '/api/login/',
        data: { username, password },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      navigate('/copilot');
    } catch (error) {
      setError('Invalid username or password');
    }
  };

  const selectChangeLanguage = (e, data) => {
    // Handle language change
  };

  return (
    <section>
      <Grid columns={2} style={{ margin: "0px", padding: "0px", minHeight: "100vh" }}>
        <Grid.Column className='left-design'>
          <img src={background} alt="Background" className="rounded-image" /> {/* Ensure image fills the div */}
        </Grid.Column>
        <Grid.Column style={{ padding: "0", backgroundColor: "#ffffff" }}>
          <div className='right-design-container'>
            <div className='lang-settings'>
              <Dropdown defaultValue={'en'} onChange={selectChangeLanguage} options={languageOptions} />
            </div>
            <div className='right-design'>
              <center>
                <img src={logo} alt="Logo" className="rounded-image" /> {/* Add the logo here */}
                <h2>Welcome to Real Estate Copilot</h2>
                <span className='label'>請輸入Copilot帳號資料</span>
                <p ref={errRef} className={error ? "errmsg" : "offscreen"} aria-live="assertive">{error}</p>
              </center>
              <form onSubmit={handleLogin}>
                <div className='login-inputs'>
                  <span className='label'>帳號</span>
                  <Input placeholder="Username" type="text" ref={userRef} onChange={(e) => setUsername(e.target.value)} value={username} required fluid />
                </div>
                <div className='login-inputs'>
                  <span className='label'>密碼</span>
                  <Input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} value={password} required fluid />
                  <div className='rem-forgot'>
                    <Grid columns={2}>
                      <Grid.Column textAlign='left'>
                        <Checkbox label="記得登入資訊" className='remember-me' checked={remember} onChange={(e, data) => setRemember(data.checked)} />
                      </Grid.Column>
                    </Grid>
                  </div>
                </div>
                <div>
                  <Button fluid style={{ backgroundColor: '#229BE4', color: '#FFFFFF' }}>登入</Button>
                </div>
              </form>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </section>
  );
};

export default Login;
