import React from 'react'
import { Label, Icon } from 'semantic-ui-react'
const StatusError = (props) => {
  return (
    <tr>
        <td className='fields-td'>{props.fields}</td>
        <td>{props.description}</td>
        <td>
            <Label color='red'>
                <Icon name='exclamation triangle'/>
                錯誤
            </Label>
        </td>
        <td>
        <Icon name='ellipsis vertical'/>
        </td>
    </tr>
  )
}

export default StatusError