import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosServer } from '../apis/axiosApi'; // Adjust the path if needed
import '../styles/home.css';

const Home = () => {
  const [user, setUser] = useState(null);
  const [address, setAddress] = useState('');
  const [caseName, setCaseName] = useState('');
  const [scriptUsername, setScriptUsername] = useState('');
  const [scriptPassword, setScriptPassword] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Sending request to run script');
      const response = await axiosServer({
        method: 'POST',
        url: 'api/run-script/', // Make sure this matches your Django URL pattern
        data: {
          address,
          case_name: caseName,
          script_username: scriptUsername,
          script_password: scriptPassword,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Script output:', response.data);
      setResponseMessage(response.data.output || 'Script executed successfully.');
    } catch (error) {
      console.error('Error running script:', error.response || error.message);
      setResponseMessage('Error running script. Please check the console for details.');
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="home-container">
      <h2>Welcome, {user.username}</h2>
      <form onSubmit={handleAddressSubmit}>
        <div>
          <label htmlFor="address">Enter Address:</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="caseName">Enter Case Name:</label>
          <input
            type="text"
            id="caseName"
            value={caseName}
            onChange={(e) => setCaseName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="scriptUsername">Enter Username:</label>
          <input
            type="text"
            id="scriptUsername"
            value={scriptUsername}
            onChange={(e) => setScriptUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="scriptPassword">Enter Password:</label>
          <input
            type="password"
            id="scriptPassword"
            value={scriptPassword}
            onChange={(e) => setScriptPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
};

export default Home;
