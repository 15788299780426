import React from 'react'
import { Label, Icon } from 'semantic-ui-react'

const StatusRunning = (props) => {
  return (
    <tr>
        <td className='fields-td'>{props.fields}</td>
        <td>{props.description}</td>
        <td>
            <Label>
                <Icon loading name='spinner'/>  
                讀取中
            </Label>
        </td>
        <td>
        <Icon name='ellipsis vertical'/>
        </td>
    </tr>
  )
}

export default StatusRunning