import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Label, Header, HeaderContent, HeaderSubheader, Icon, ButtonContent, Button } from 'semantic-ui-react'

import { axiosServer } from '../apis/axiosApi';
import "../styles/output.css"
import StatusTable from '../components/statusTable'

const temp_cn = {
  "地籍資訊網站": "[建號, 地段, 地號]",
  "臺北市建管業務網站": "違建查報查詢截圖",
  "台灣圖霸" : "周邊環境列表截圖", 
  "學區查詢系統": "對應學區學校",
  "Google Maps" : "環境交通",
  "地理資訊e點通" : "路寬截圖",
  "地政資訊e點通" : "建物謄本, 土地謄本, 測量成果圖, 地籍圖",
  "台北市土地使用分區查詢" : "土地使用分區列表",
  "財政部稅務入口網" : "土地增值稅試算",
  "網路執照存根影像查詢" : "使用執照圖片",
  "ERA管理後台" : "填寫開發物件"
} // (如果地政資訊e點沒有他項權利部會有錯誤)

const temp_en = {
  "Easymap": "[Building ID, Land Section, Land ID]",
  "Illegal Construction": "Illegal constructions screenshot",
  "Vicinity Map" : "Vicinity map screenshot", 
  "School District": "Associated school districts",
  "Google Maps" : "Nearby landmarks/transportation",
  "Road Width" : "Road width map screenshot",
  "Registry Info" : "Building Info, Land Info, Floor plan, Land map",
  "Zoning Map" : "Zoning Map screenshot",
  "Tax Estimation" : "Tax estimation screenshots for each land",
  "User Permit" : "User Permit images",
  "ERA platform form" : "Fill out ERA form"
}

const default_status = {
  "地籍資訊網站": "Waiting",
  "臺北市建管業務網站": "Waiting",
  "台灣圖霸" : "Waiting",
  "學區查詢系統": "Waiting",
  "Google Maps" : "Waiting",
  "地理資訊e點通" : "Waiting",
  "地政資訊e點通" : "Waiting",
  "台北市土地使用分區查詢" : "Waiting",
  "財政部稅務入口網" : "Waiting",
  "網路執照存根影像查詢" : "Waiting",
  "ERA管理後台" : "Waiting"
}

var fileDownload = require('js-file-download');

const Output = (props) => {
  var is_downloadable = false;
  let location = useLocation();
  // const [sitesDescription, setSitesDescription] = useState()
  const sitesDescription = temp_cn;
  const [pdfDownloadable, setPdfDownloadable] = useState();
  const [siteStatuses, setSiteStatuses] = useState('');
  
  const navigate = useNavigate();

  const renderStatusTables = () => {
    console.log("rerender")
    // render table doms with correct status based on passed in response
    if ( Object.keys(sitesDescription).length > 0) {    
        console.log("Function object", siteStatuses)
        var temp = []
        for (const [key, value] of Object.entries(sitesDescription)) {
          temp.push(<StatusTable key={key} siteName={key} siteFields={value} siteStatus={siteStatuses[key]} />);
        }
        return temp
    }
    else return [];
  }

  const getScrapeStatuses = async () => {
    try {
      const response = await axiosServer({
        method: 'POST',
        url: 'api/get-status/',
        data: {
          case_name : location.state.case_name,
          case_id : location.state.case_id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("Old data: ", siteStatuses)
      console.log("Poll data: ", response.data)

      setSiteStatuses(response.data);
      if (!is_downloadable && (response.data["ERA管理後台"] === "Success" || response.data["ERA管理後台"] === "Error")){
        setPdfDownloadable(true)
        alert("點擊綠色 「下載 .pdf」 按鈕, 會下載相關圖片及截圖")
        is_downloadable = true
      }
      renderStatusTables(response.data)
    } catch (e) {
      console.log(e);
    }
  };
  const getPDF = async() => {
    let result = "none";
    try{
      await axiosServer({
          method: 'GET',
          url: `api/export-pdf/?case_name=${location.state.case_name}&case_id=${location.state.case_id}`,
          responseType: "blob",
      })
      .then(res => {
          fileDownload(res.data, `${location.state.case_name}.pdf`);
          console.log(res);
      })
      .catch(e =>
          {console.log(e)}
    )} catch (e){
        console.log(e)
    }
  }

  const goToCopilot = () => {
    var answer = window.confirm('確認要離開去新增案件? pdf檔案如果沒下載, 之後無法取得');
    if (answer) {
      // Save it!
      navigate('/copilot');
    } else {
      // Do nothing!
      // console.log('Thing was not saved to the database.');
    }
  }

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getScrapeStatuses();
    }, 1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  return (
    <div className='white-background'>
        <div className='main-page-container'>
          <div className="output-header">
            <Header>
              <Icon name='home'/>
              <HeaderContent>
                {location.state.address.concat(" ID: ", location.state.case_id)}
              </HeaderContent>
            </Header>
          </div>
          <Button onClick={goToCopilot} id="prev-button" animated="fade">
              <ButtonContent visible><Icon name='plus' />新增案件</ButtonContent>
              <ButtonContent hidden>
                <Icon name='search plus' />
                <Icon name='home' />
              </ButtonContent>
          </Button>
          <Label onClick={getPDF} as={pdfDownloadable ? "a":"button"} color={pdfDownloadable ? "green":"grey"} content='下載 .pdf 檔案' icon='download' id='download-pdf-btn'/>
          <div className='tables-container'>
            {renderStatusTables()}
          </div>
            
        </div>
    </div>
    
  )
}

export default Output